export const environment = {
  production: false,
  apiUrl: 'https://api-demo.shipskart.com',
  socketUrl: 'https://api-demo.shipskart.com',

  attachementUrl: 'https://shipskart-combined.s3.ap-south-1.amazonaws.com/',
  firebase: {
    apiKey: "AIzaSyDLnpDZ-jQ7GMXXqRukaOlmej-UQHKYzvE",
    authDomain: "shipskartbetav2web.firebaseapp.com",
    projectId: "shipskartbetav2web",
    storageBucket: "shipskartbetav2web.appspot.com",
    messagingSenderId: "636162001343",
    appId: "1:636162001343:web:100e20f0f1fbf12b8f390a"
  },
};

export const ETSTEUR = "IOPYYT944223631QAZ0PLM83SYITXRAABBRTX";
export const YETTSS = "11908IOIO25385621QAZ0PLM!729TGIEMW1098KLO";